// ** React, Next & Mui
import { ChangeEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
  AvatarGroup,
  Tooltip,
  Button,
} from '@mui/material'

// ** Components
import InfiniteScroll from 'react-infinite-scroll-component'
import PageContainer from '../container/PageContainer'
import BlankCard from '../shared/BlankCard'
import DashboardCard from '../shared/DashboardCard'
import ProfileHover from '../shared/ProfileHover'

// ** Hooks & utils
import { useBackend } from '../../context/backend'
import { getAvatarUrl } from '../../utils'
import { RATIO_NAME } from '../../utils/constants/literals'

/////////////////////////////////////////////
export default function PeopleComponent() {
  const router = useRouter()
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { fetchUsersTrendings, trendingUsersCache } = useBackend()

  const [ratio, setRatio] = useState<string>('Followers')
  const [prevRatio, setPrevRatio] = useState<string>('')
  const [isLoading, setIsLoading] = useState<Boolean>(false)

  const [adjusted, setAdjusted] = useState<string>('Global')
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
    total: 0,
    totalPages: 1,
  })

  useEffect(() => {
    if ('liked' in router.query) {
      setRatio('Likes')
    } else if ('followed' in router.query) {
      setRatio('Followers')
    } else if ('collected' in router.query) {
      setRatio('Collects')
    }

    if (!trendingUsersCache?.usersWithRatio.length || ratio !== prevRatio) {
      setIsLoading(true)
      getUsersData()
    }
  }, [ratio, adjusted, router.query])

  const getUsersData = async (page?: number) => {
    if (ratio === 'none') return
    
    try {
      const { pagination: resPagination } = await fetchUsersTrendings(
        ratio,
        adjusted,
        page
      )

      setPrevRatio(ratio)
      setPagination(resPagination)
      setIsLoading(false)
    } catch (error) {
      console.log('Error fetching users data', error)
    }
  }

  const handleRatioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setRatio(value === '' ? 'none' : value)
  }

  const handlePageChange = async () => {
    if (pagination.page < pagination.totalPages) {
      await getUsersData(pagination.page + 1)
    }
  }

  /*************************************************
   *                    Render                     *
   *************************************************/

  const DesktopTable = () => (
    <BlankCard>
      <InfiniteScroll
        dataLength={trendingUsersCache?.usersWithRatio.length || 0}
        next={handlePageChange}
        hasMore={pagination.page < pagination.totalPages}
        loader={
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            marginY="2vh"
            overflow="hidden"
          >
            <CircularProgress />
          </Box>
        }
      >
        <TableContainer>
          <Table aria-label="people table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Users</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: '#f0f0f0' }}
                >
                  <Typography variant="h6">
                    {RATIO_NAME[ratio][adjusted]}
                  </Typography>
                  <Typography variant="body2">Last 7 days</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">Total followers</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">Followed by</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">Lens score</Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {trendingUsersCache?.usersWithRatio.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                      transition: 'background-color 0.3s',
                    },
                  }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          '& .avatar': {
                            filter: 'brightness(0.5)',
                            transition: '0.2s',
                          },
                          '& .name': {
                            color: theme.palette.primary.main,
                            transition: '0.2s',
                          },
                          '& .handle': {
                            color: theme.palette.grey[400],
                            transition: '0.2s',
                          },
                        },
                      }}
                    >
                      <ProfileHover key={user.id} user={user}>
                        <Link
                          href={`/profile/${user.id}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <Stack
                            key={user.id}
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                '& .avatar': {
                                  filter: 'brightness(0.5)',
                                  transition: '0.2s',
                                },
                                '& .name': {
                                  color: theme.palette.primary.main,
                                  transition: '0.2s',
                                },
                                '& .handle': {
                                  color: theme.palette.grey[400],
                                  transition: '0.2s',
                                },
                              },
                            }}
                          >
                            <Avatar
                              className="avatar"
                              src={getAvatarUrl(user)}
                              sx={{ width: 42, height: 42 }}
                            />
                            <Box>
                              <Typography variant="h6" className="name">
                                {user.metadata?.displayName}
                              </Typography>
                              <Typography variant="caption" className="handle">
                                {user.handle?.suggestedFormatted.localName}
                              </Typography>
                            </Box>
                          </Stack>
                        </Link>
                      </ProfileHover>
                    </Box>
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                    style={{ backgroundColor: '#f0f0f0' }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      {Number(user.ratio).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      {user.stats.followers.toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <Box display="flex" justifyContent="center">
                      <AvatarGroup sx={{ justifyContent: 'start' }}>
                        {user.topFollowers.map((follower, i) => (
                          <Tooltip title={follower.handle} key={i}>
                            <Avatar
                              src={follower.profilePic}
                              alt={follower.handle}
                              sx={{ width: 35, height: 35 }}
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Box>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      {user.stats.lensClassifierScore.toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <Link
                      href={`/profile/${user.id}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      passHref
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          '&:hover': {
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                            transform: 'scale(1.1)',
                            transition: 'transform 0.2s, color 0.2s',
                          },
                        }}
                      >
                        <Button size="small" variant="outlined">
                          View
                        </Button>
                      </Box>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </BlankCard>
  )

  const MobileTable = () => (
    <BlankCard>
      <InfiniteScroll
        dataLength={trendingUsersCache?.usersWithRatio.length || 0}
        next={handlePageChange}
        hasMore={pagination.page < pagination.totalPages}
        loader={
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            marginY="5vh"
            overflow="hidden"
          >
            <CircularProgress />
          </Box>
        }
      >
        <TableContainer>
          <Table aria-label="people table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Users</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Lens Data</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trendingUsersCache?.usersWithRatio.map((user) => (
                <TableRow key={user.id}>
                  <TableCell sx={{ width: '4em' }}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      spacing={2}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          '& .avatar': {
                            filter: 'brightness(0.5)',
                            transition: '0.2s',
                          },
                          '& .name': {
                            color: theme.palette.primary.main,
                            transition: '0.2s',
                          },
                          '& .handle': {
                            color: theme.palette.grey[400],
                            transition: '0.2s',
                          },
                        },
                      }}
                    >
                      <Link
                        href={`/profile/${user.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        passHref
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Avatar
                            className="avatar"
                            src={getAvatarUrl(user)}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box>
                            <Typography
                              variant="body1"
                              fontSize="1em"
                              fontWeight="bold"
                              className="name"
                            >
                              {user.metadata?.displayName}
                            </Typography>
                            <Typography
                              variant="body2"
                              fontSize="1em"
                              className="handle"
                            >
                              {user.handle?.suggestedFormatted.localName}
                            </Typography>
                          </Box>
                        </Stack>
                      </Link>
                      <Link
                        href={`/profile/${user.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        passHref
                      >
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              cursor: 'pointer',
                              color: theme.palette.primary.main,
                              transform: 'scale(1.1)',
                              transition: 'transform 0.2s, color 0.2s',
                            },
                          }}
                        >
                          <Button size="small" variant="outlined">
                            View
                          </Button>
                        </Box>
                      </Link>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ width: '2em' }}>
                    <Stack
                      direction="row"
                      spacing={0.3}
                      style={{ backgroundColor: '#f0f0f0' }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontWeight="bold"
                      >
                        {RATIO_NAME[ratio][adjusted]}:
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {Number(user.ratio).toLocaleString()}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={0.3}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontWeight="bold"
                      >
                        Followers:
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {user.stats.followers.toLocaleString()}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={0.3}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontWeight="bold"
                      >
                        Lens score:
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {user.stats.lensClassifierScore.toLocaleString()}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={0.3}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        fontWeight="bold"
                      >
                        Posts:
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {user.stats.posts.toLocaleString()}
                      </Typography>
                    </Stack>

                    <Stack direction="row">
                      <Box display="flex" my="0.4em" justifyContent="center">
                        <AvatarGroup sx={{ justifyContent: 'start' }}>
                          {user.topFollowers.map((follower, i) => (
                            <Tooltip title={follower.handle} key={i} arrow>
                              <Avatar
                                src={follower.profilePic}
                                alt={follower.handle}
                                key={i}
                                sx={{ width: 25, height: 25 }}
                              />
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </BlankCard>
  )

  return (
    <PageContainer>
      <DashboardCard
        title="People trends (last 7 days)"
        contentPadding={isSmScreen ? '5px' : undefined}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : trendingUsersCache?.usersWithRatio &&
          trendingUsersCache?.usersWithRatio.length ? (
          isSmScreen ? (
            <MobileTable />
          ) : (
            <DesktopTable />
          )
        ) : (
          <Stack direction="column">
            <Typography variant="h5" align="center">
              No data available
            </Typography>

            <Typography variant="subtitle1" align="center">
              Please select another field or try again later.
            </Typography>
          </Stack>
        )}
      </DashboardCard>
    </PageContainer>
  )
}
